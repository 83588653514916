/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"zamek"} description={"zamek"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"x59hobd2kyt"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Zadlabací zámek"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"9ou748ngnd7"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"Měříme zadlabací zámek"}>
              </Title>

              <Text className="text-box" content={"Nejdůležitější jsou celkem čtyři parametry.<br>1. ROZTEČ<br>2. DORN<br>3. ZÁDLAB<br>4. ORIENTACE<br><br>Rozteče jsou tři základní 72, 90 a 92 a měří ze od osy cylindrické vložky k ose ořechu kliky. Rozteč 92 je typická pro profilové dveře, jako jsou například hliníkové, nebo plastové vstupní dveře. DORN je vzdálenost od čela zámku k ose cylindrické vložky, nebo k ose ořechu kliky. Zádlab je celková hloubka zámku. Vše je názorně zobrazeno na obrázku. Dalším parametrem je orientace dveří. Zámky můžou mít možnost otočit orientaci, ale některé jsou pouze pro pravé, nebo levé dveře a nelze je zaměnit. Zámky můžeme mít na obyčejný tvarový klíč, dozický klíč a nebo na cylindrickou vložku.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/3e4363ab075d4de5b7d9692219c7777f_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/3e4363ab075d4de5b7d9692219c7777f_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/3e4363ab075d4de5b7d9692219c7777f_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/3e4363ab075d4de5b7d9692219c7777f_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-2);\">Rádi vyhovíme Vašim přáním a požadavkům</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}